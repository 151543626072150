/* eslint-disable max-len */
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CategorieForm from '../../core/sysext/Boutique/containers/Produit/containers/Categorie/components/Form';
import CategorieList from '../../core/sysext/Boutique/containers/Produit/containers/Categorie/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const CategoriesPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        <CategorieForm docId={star} {...props} />
      ) : (
        <CategorieList {...props} />
      )}
    </Backoffice>
  );
};

export default CategoriesPage;
